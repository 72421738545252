// COLOR VARIABLE DEFINITIONS
$black: #000;

$black_00: $black; // #000000;
$black_10: lighten($black, 10%); // #1a1a1a
$black_20: lighten($black, 20%); // #333333
$black_25: lighten($black, 25%); // #404040
$black_30: lighten($black, 30%); // #4d4d4d
$black_40: lighten($black, 40%); // #666666
$black_50: lighten($black, 50%); // #808080

$white: #fff;
$white_00: $white; // #ffffff;
$white_05: darken($white, 05%); // #f2f2f2
$white_10: darken($white, 10%); // #e6e6e6
$white_15: darken($white, 15%); // #d9d9d9
$white_20: darken($white, 20%); // #cccccc
$white_25: darken($white, 25%); // #bfbfbf
$white_30: darken($white, 30%); // #b3b3b3
$white_35: darken($white, 35%); // #a6a6a6
$white_40: darken($white, 40%); // #999999
$white_45: darken($white, 45%); // #8c8c8c
$white_50: darken($white, 50%); // #808080

$logo_accent_orange: #fa4c06;
$logo_accent_orange_light: #fd7b40;
$anchor_link: #fa4c06;
$accent_orange: #fda630;

$accent_red: #8e1a1d;
$accent_red_highlight: #b42226;

$white_trans: rgba($white, 0.4);

$black_semi_trans: rgba($black, 0.5);
$black_semi_opaque: rgba($black, 0.75);

// FORMS
$form_good: #13c607;

@import 'base/color_definitions';

// DEFAULT
$font_color_base: $white;

// THEME COLOR
$theme_font_color: $font_color_base;

$fnt_clr_dkst: darken($theme_font_color, 10%);
$fnt_clr_dker: darken($theme_font_color, 20%);

a,
h1,
h2,
h3,
h4,
h5,
h6,
li,
p,
ul { color: $theme_font_color; }

a:hover { color: lighten($theme_font_color, 10%); }

.bg-accent-red {
  background-color: $accent_red;
  color: $white_00;
}

.fnt-accent-red {
  color: $accent_red;
}

.bg-accent-orange {
  background: $accent_orange;
  color: $white_00;
}

.fnt-accent-orange {
  color: $accent_orange;
}

.red { color: $accent_red; }

// Enhanced Fujita Branding
img.ef-branding {
    max-width:80%;
    margin: 0 auto 30px;
    display:block;
}

.side-nav .ef-branding {
    position:absolute;
    bottom: 0;

    img {
        max-width:30%;
        margin: 20px auto;
        display:block;
    }
}

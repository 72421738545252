body {
    background-color:$white;

    &.login {
        background-color:$white_05;
    }
}

a,
h1,
h2,
h3,
h4,
h5,
h6,
li,
p,
ul { color: $black; }

#page-wrapper {
    position:relative;
    padding:10px 15px;
}

.page-header {
    padding-bottom:0;
    margin-top:54px;
}

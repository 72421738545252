.navbar {
    border-radius: 0;
}

.navbar-brand img {
    height:22px;
}

.nav {
    li {
        a:hover,
        a:focus {
            background-color:$accent_red;
        }
    }
}

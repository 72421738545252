// TODO Pull in styles for _ui from seed EF project
.hidden,
.closed { display: none; }

.disabled { cursor: not-allowed; }

p a {

    &:hover {
        color:initial;
    }
}

.alert {
    position:absolute;
    top:0;
    right:0;
    left:0;
    border-radius:0;
}

.section {
    display:none;

    &.active {
        display:block;
    }
}

.panel-default {
    > .panel-heading {
        background-color:$white_10;

        &.tab-container,
        &.cms {
            padding-bottom: 0;
        }
    }
}

/* Panel Tabs */
ul.tabs {
    margin:0;
    padding:0;
    display:inline-block;

    li {
        display:inline-block;
        border:1px solid rgba(0,0,0,0.2);
        border-bottom:none;
        border-radius:5px 5px 0 0;
        list-style-type:none;
        vertical-align: top;

        &.active {
            background:#fff;
        }

        a {
            display: block;
            padding: 10px 20px;

            &.disabled {
                display:none;
            }
        }
    }
}

/* Images */
#current_uploaded {
    margin: 20px 0;
    padding:0;
    height:250px;
    overflow-y:auto;
    background:#f5f5f5;

    > li {
        background:#eee;
        width:33%;
        float:left;
    }
}

.cms-page img {
    max-height:325px;
    margin:0 auto;
}

.img {
    background: #ccc;
    display: inline-block;
    overflow: hidden;

    img {
        opacity:0;
        transition: opacity 1s;
    }

    &.loaded {
        img {
            opacity: 1;
        }
    }
}

.stored_images {
    display:block;
    padding:10px;
    transition:background-color 0.3s ease;
    height:50px;
    overflow:hidden;

    &:hover {
        background-color:rgba(0,0,0,0.1);
        color:#2260a2;
    }
}

label.clickable {
    display: inline-block;
    padding:10px;
    margin: 0 0 10px;

    &:hover {
        cursor: pointer;
        background-color:$white_10;
    }
}

select.full {
    background-color: $white;
    padding:5px;
    display:block;
    width:100%;
    height:34px;
    border-color:#ccd0d2;
}

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// SB Admin
@import 'admin/admin';

// Font Awesome
@import 'node_modules/font-awesome/scss/font-awesome';

// Select2
@import 'node_modules/select2/dist/css/select2';

// DataTables
@import 'node_modules/datatables.net-bs/css/dataTables.bootstrap';

// Custom Styles
@import 'base/color_definitions';

@import 'colors';
@import 'admin/header';
@import 'admin/nav';
@import 'admin/footer';
@import 'admin/ui_components';
@import 'admin/layout';
